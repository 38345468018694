<template>
  <v-container id="home" class="bg2">
    <v-row no-gutters justify="center">
      <v-col xs="12" sm="8">
        
        <v-btn v-if="user" block class="headline py-6 mb-4 bg3" to="/menu/user">
          <v-row no-gutters class="d-flex justify-space-between">
            <span class="text-capitalize">{{user.name}}</span>
            <v-icon right class="headline pt-2">mdi-dots-vertical</v-icon>
          </v-row>
        </v-btn>

        <v-btn block class="vegas vegas-font black--text py-6 mb-4" to="/menu/vegas">
          <v-row no-gutters class="d-flex justify-space-between">
            Vegas
            <v-icon right class="headline pt-4">mdi-dots-vertical</v-icon>
          </v-row>
        </v-btn>

        <v-btn block class="bfg bfg-font white--text py-6 mb-4" to="/menu/bfg">
          <v-row no-gutters class="d-flex justify-space-between">
            <span><span class="caption text-lowercase">the</span>BFG</span>
            <v-icon right class="headline pt-4">mdi-dots-vertical</v-icon>
          </v-row>
        </v-btn>

        <v-btn block class="smss smss-font white--text py-6 mb-4" to="/menu/smss">
          <v-row no-gutters class="d-flex justify-space-between">
            SM$S
            <v-icon right class="headline pt-4">mdi-dots-vertical</v-icon>
          </v-row>
        </v-btn>

        <v-btn block class="mbwed mbwed-font white--text py-6 mb-4" to="/menu/mbWed">
          <v-row no-gutters class="d-flex justify-space-between">
            Aftermath
            <v-icon right class="headline pt-4">mdi-dots-vertical</v-icon>
          </v-row>
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'home',
  data: () => {
    return {
      
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>
