<template>
<v-app>
  <v-container :dark="dark" class="inter ma-0 pa-0">

      <v-slide-x-transition leave-absolute>
        <component :is="layout" class="page"></component>
      </v-slide-x-transition>

      <v-snackbar top :value="snackbar">
        {{snackbar}}
        <v-btn text color="error" @click.native="clearSnack">Close</v-btn>
      </v-snackbar>

      <v-snackbar top vertical :value="confirm">
        {{confirm}}
        <v-btn text color="error" @click.native="confirm = false">No</v-btn>
        <v-btn text color="primary" @click.native="confirm = false">Yes</v-btn>
      </v-snackbar>

      <v-overlay :value="overlay" z-index="10">
        <v-col cols="12">
        <v-sheet class="grey lighten-2 secondary--text caption pa-4">
          {{overlay}}
          <v-progress-linear indeterminate color="primary" class="mt-4"></v-progress-linear>
        </v-sheet>
        </v-col>
      </v-overlay>

      <v-dialog content-class="mx-0" :value="roundDialog" persistent max-width="400px">
        <v-card class="bg3">
          <v-card-text class="px-1">
            <div v-if="roundDialog">
              <scores :side="3" :round="roundDialog.round" edit="false" :cal="roundDialog.cal" />
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row no-gutters justify="end">
              <v-btn text color="primary" @click.stop="clearRoundDialog">close</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</v-app>
</template>

<script>
import { mixin } from '@/js/mixin'
import { fs, roundRef, calRef } from '@/db'
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore";
import moment from 'moment'
import Scores from '@/components/Scores'
import AppView from '@/layouts/AppView'
import FullView from '@/layouts/FullView'
//import _ from 'lodash'

export default {
  name: 'App',
  mixins: [mixin],
  components: {
    'scores': Scores,
    'app-layout': AppView,
    'full-layout': FullView
  },
  data: () => ({
    dark: true,
    key: 0,
    cal: {},
    live: {},
    confirm: false,
    round: null,
    calUnsub: null,
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    currentRounds() {
      return this.$store.getters.getCurrentRounds
    },
    layout() {
      return this.$store.getters.getLayout
    },
    snackbar() {
      return this.$store.getters.getSnackbar
    },
    overlay() {
      return this.$store.getters.getOverlay
    },
    // _courses() {
    //   return this.$store._courses
    // },
    courses() {
      return this.$store.getters.getCourses
    },
    liveRounds() {
      return this.$store.getters.getLiveRounds
    },
    rounds() {
      return this.$store.getters.getRounds
    },
    roundDialog() {
      return this.$store.getters.getRoundDialog
    },
  },
  methods: {
    clearSnack() {
      this.$store.dispatch('setSnackbar', null)
    },
    clearRoundDialog() {
      this.$store.dispatch('setRoundDialog', null)
    },   
  },
  watch: {
    user(u) {
      if (u) this.$vuetify.theme.dark = u.prefs.dark
    },
  },
  async created() {
    if (this.user) this.$vuetify.theme.dark = this.user.prefs.dark
    let cRef = query(calRef, where('iso', '==', moment().format('YYYY-MM-DD')))
    this.calUnsub = onSnapshot(cRef, calDocs => {
      let cal = []
      calDocs.forEach(doc => {
        cal.push(doc.data())
      })
      this.$store.dispatch('setCalendar', cal)
      console.log('calendar updated'); 
    })


    let courseDocs = await getDocs(collection(fs,'courses'))
    if (courseDocs.docs.length > 0) {
      let c = {}
      courseDocs.forEach(course => {
        c[course.id] = course.data()
      })
      this.$store.dispatch('setCourses', c)
      console.log('courses updated')
    }

    let rRef = query(roundRef, where('iso', '==', moment().format('YYYY-MM-DD')))
    let docs = await getDocs(rRef)
    if(docs.docs.length > 0) {
      console.log(docs.docs[0].data());
      let r = {}
      let a = []
      docs.docs.forEach (d => {
        r[d.id] = d.data()
        if (d.data().playerKey == this.user.playerKey && d.data().status == 'active') a = d.data().roundKeys 
      })
      let x = {}
      a.forEach(ar => {
        x[ar] = r[ar]
      })
      // roundDocs.forEach(doc => {
      //   r[doc.id] = doc.data()
      // })
      console.log(x, this.currentRounds);
      if (!this.currentRounds  && Object.keys(x).length > 0) {
        this.$store.dispatch('setCurrentRounds', x)
        this.$router.push('/scorecard/')
      } 
    }
    
    console.log('App - live read')

    
  },
  async destroyed() {
    if (this.calUnsub) this.calUnsub()
  },
}
</script>

<style>
  * { touch-action: manipulation; }
  .inter { font-family: 'Inter', sans-serif !important; }
  .vegas-font { font-family: 'Salsa', cursive !important; font-size: 1.75em !important; font-style: italic !important; letter-spacing: -1px !important; }
  .smss-font { font-family: 'Days One', sans-serif !important; font-size: 1.75em !important; letter-spacing: -0.5px !important; }
  .mbwed-font { font-family: 'Trade Winds', cursive !important; font-size: 1.75em !important; letter-spacing: -0.5px !important; }
  .bfg-font {  font-family: 'Racing Sans One', cursive !important; font-size: 1.75em !important; letter-spacing: -0.5px !important; }
  .nav { color: #33cc33 !important; border-bottom: 2px solid !important; }
  .nav:before { background-color:transparent !important; }
  .page { position: absolute; width: 100% !important; }
  .scroll { overflow-y: auto; max-height: 50vh;}
  .line-h1 { line-height: 1 !important;}
  .score { text-align: center; position: relative; margin: 0 2px; padding-top: 2px; }
  .dd { background: grey !important;}
  .hole-label { font-size: .8em; font-weight: 400; letter-spacing: -0.1em; }
  .badge { color: white; font-size: 10px; letter-spacing: -0.05em; line-height: 10px; padding: 2px 4px 2px 3px; border-radius: 50px; position: absolute; }
  .ur { top: -4px; right: -4px; background: darkgreen;}
  .ll { bottom: -4px; left: -4px; background: darkred; }
  .lr { bottom: -4px; right: -4px; background: darkblue; }
  .ul { top: -4px; left: -4px; background: darkblue; }
  .pops { position: absolute; top: 5px; left: 8px; line-height: 0; }
  .condense { font-size: .8em; letter-spacing: -0.05em !important; }
  .mx-1px { margin-left: 1px; margin-right: 1px;}
</style>