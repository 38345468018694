import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { fa, fs, playerRef } from './db'
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
//import moment from 'moment'
//import { today } from './js/shared'

Vue.config.productionTip = false
Vue.config.devtools = true

let app = false

fa.onAuthStateChanged(user => {
  console.log('user', user)
  if (user) {
    store.dispatch('setOverlay', 'Getting user info...')
    if (user.phoneNumber) {
      let pRef = query(playerRef, where('phone', '==', user.phoneNumber))
      getDocs(pRef).then(phone => {
        let u = phone.docs[0].data()
        console.log(u);
        if (u.uids.indexOf(user.uid) < 0) {
          u.uids.push(user.uid)
          updateDoc(doc(playerRef, phone.docs[0].id),{uids: u.uids})
        }
        u.playerKey = phone.docs[0].id
        store.dispatch('setUser', u)
        router.replace('/')
        store.dispatch('setOverlay', null)
      })
    } else {
      let pRef = query(playerRef, where('uids', 'array-contains', user.uid))
      getDocs(pRef).then(doc => {
        let u = doc.docs[0].data()
        u.playerKey = doc.docs[0].id
        store.dispatch('setUser', u)
        router.replace('/')
        store.dispatch('setOverlay', null)
      })
    }
  } else {
    store.dispatch('setUser', null)
    store.dispatch('setCurrentRounds', null)
    router.replace('/')
    store.dispatch('setOverlay', null)
  }
  
  if (!app) {
    app = true
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

})



