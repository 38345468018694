import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '@/views/Home.vue'
const AppSettings = () => import('@/views/AppSettings')
const Login = () => import('@/views/user/Login')
const Register = () => import('@/views/user/Register')
const Forgot = () => import('@/views/user/Forgot')
const Logout = () => import('@/views/user/Logout')
const MenuList = () => import('@/views/MenuList')
const NewRound = () => import('@/views/round/NewRound')
const Scorecard = () => import('@/views/Scorecard')
const Leaders = () => import('@/views/Leaders')
const PastRounds = () => import('@/views/PastRounds')
const Players = () => import('@/views/Players')
const Teesheet = () => import('@/views/Teesheet')
const Test = () => import('@/views/Test')
const EditCalendar = () => import('@/views/EditCalendar')
const EditCurrentRounds = () => import('@/views/EditCurrentRounds')
const EditAllPlayers = () => import('@/views/EditAllPlayers')
const EditRounds = () => import('@/views/EditRounds')
const PlayerStats = () => import('@/views/PlayerStats')
const Yearlong = () => import('@/views/Yearlong')
const Payout = () => import('@/views/Payout')
const EditPayout = () => import('@/views/EditPayout')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/settings',
    name: 'AppSettings',
    component: AppSettings,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/newround/:type?',
    name: 'NewRound',
    component: NewRound,
    // beforeEnter(to, from, next) {
    //   if (store.getters.getCourses) {
    //     next()
    //   } else {
    //     next('/')
    //   }
    // }
  },
  {
    path: '/scorecard',
    name: 'Scorecard',
    component: Scorecard,
    beforeEnter(to, from, next) {
      if (store.getters.getCurrentRounds) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/leaders/:live?/:type?/:year?/:date?',
    name: 'Leaders',
    component: Leaders,
  },
  {
    path: '/past-rounds/:type?',
    name: 'PastRounds',
    component: PastRounds,
  },
  {
    path: '/edit-payout/:type?',
    name: 'EditPayout',
    component: EditPayout,
  },
  {
    path: '/calendar/:type?/:year?',
    name: 'Calendar',
    component: PastRounds,
  },
  {
    path: '/edit-calendar/:type?/:year?/:key?',
    name: 'EditCalendar',
    component: EditCalendar,
  },
  {
    path: '/players/:type?',
    name: 'Players',
    component: Players,
  },
  {
    path: '/teesheet/:type?/:year?/',
    name: 'Teesheet',
    component: Teesheet,
  },
  {
    path: '/payouts/:type?/:year?/',
    name: 'Payout',
    component: Payout,
  },
  {
    path: '/yearlong/:type?/:year?/:game?/',
    name: 'Yearlong',
    component: Yearlong,
  },
  {
    path: '/menu/:type?',
    name: 'Menu',
    component: MenuList,
  },
  {
    path: '/edit-current-rounds/',
    name: 'EditCurrentRounds',
    component: EditCurrentRounds,
  },
  {
    path: '/edit-all-players/',
    name: 'EditAllPlayers',
    component: EditAllPlayers,
  },
  {
    path: '/edit-rounds/',
    name: 'EditRounds',
    component: EditRounds,
  },
  {
    path: '/player-stats/:key',
    name: 'PlayerStats',
    component: PlayerStats,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router
