<template>
  <v-app id="full-view">
    <!-- <v-container fluid class="pa-0"> -->
      <v-slide-x-transition leave-absolute>
        <router-view />
      </v-slide-x-transition>
    <!-- </v-container> -->
  </v-app>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>