import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#33cc33',
        secondary: '#616161',
        accent: '#FB8C00',
        error: '#b71c1c',
        vegas: '#FDD835',
        smss: '#500000',
        mbwed: '#000982',
        bfg: '#345200',
        bg2: "#eee",
        bg3: "#ddd",
        bg: '#fafafa',
        wb: '#000'
      },
      dark: {
        primary: '#33cc33',
        secondary: '#616161',
        accent: '#FB8C00',
        error: '#E57373',
        vegas: '#FFEB3B',
        smss: '#500000',
        mbwed: '#000982',
        bfg: '#345200',
        bg2: '#222',
        bg3: '#333',
        bg: '#303030',
        wb: '#FFF'
      }
    },
  },
});
