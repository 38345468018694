import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: 'app-layout',
    rounds: null, 
    current_rounds: null,
    live_rounds: null,
    user: null,
    courses: null,
    players: null,
    calendar: [],
    snackbar: null,
    overlay: 'Loading...',
    round_dialog: null,
  },
  getters: {
    getRounds(state) {
      return state.rounds
    },
    getLayout(state) {
      return state.layout
    },
    getCurrentRounds(state) {
      return state.current_rounds
    },
    getLiveRounds(state) {
      return state.live_rounds
    },
    getUser(state) {
      return state.user
    },
    getCourses(state) {
      return state.courses
    },
    getPlayers(state) {
      return state.players
    },
    getCalendar(state) {
      return state.calendar
    },
    getSnackbar(state) {
      return state.snackbar
    },
    getOverlay(state) {
      return state.overlay
    },
    getRoundDialog(state) {
      return state.round_dialog
    },
  },
  mutations: {
    SET_ROUNDS(state, payload) {
      state.rounds = payload
    },
    SET_CURRENT_ROUNDS(state , payload) {
      state.current_rounds = payload
    },
    UPDATE_HOLE(state, payload) {
      if ((state.current_rounds[payload[0]].scores[payload[1]] + payload[2]) >= 0) state.current_rounds[payload[0]].scores[payload[1]] += payload[2]
    },
    SET_LIVE_ROUNDS(state, payload) {
      state.live_rounds = payload
    },
    SET_USER(state , payload) {
      state.user = payload
    },
    SET_COURSES(state , payload) {
      state.courses = payload
    },
    PUSH_COURSES(state , payload) {
      state.courses.push(payload)
    },
    SET_PLAYERS(state , payload) {
      state.players = payload
    },
    SET_CALENDAR(state , payload) {
      state.calendar = payload
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload
    },
    SET_ROUND_DIALOG(state, payload) {
      state.round_dialog = payload
    },
  },
  actions: {
    setRounds({commit}, payload) {
      commit('SET_ROUNDS', payload)
    },
    setCurrentRounds({commit}, payload) {
      commit('SET_CURRENT_ROUNDS', payload)
    },
    updateHole({commit}, payload) {
      commit('UPDATE_HOLE', payload)
    },
    setLiveRounds({commit}, payload) {
      commit('SET_LIVE_ROUNDS', payload)
    },
    setUser({commit}, payload) {
      commit('SET_USER', payload)
    },
    setCourses({commit}, payload) {
      commit('SET_COURSES', payload)
    },
    pushCourses({commit}, payload) {
      commit('PUSH_COURSES', payload)
    },
    setPlayers({commit}, payload) {
      commit('SET_PLAYERS', payload)
    },
    setCalendar({commit}, payload) {
      commit('SET_CALENDAR', payload)
    },
    setSnackbar({commit}, payload) {
      commit('SET_SNACKBAR', payload)
    },
    setOverlay({commit}, payload) {
      commit('SET_OVERLAY', payload)
    },
    setRoundDialog({commit}, payload) {
      commit('SET_ROUND_DIALOG', payload)
    },
  },
})


