import _ from 'lodash'
import { fs, roundRef, playerRef, prevRoundRef, calRef } from '../db'
import { getDoc, getDocs, setDoc, updateDoc, query, where, orderBy, collection, doc } from 'firebase/firestore'
import moment from 'moment'

export const mixin = {
  computed: {
    courses() {
      return this.$store.getters.getCourses
    },
    calendar() {
      return this.$store.getters.getCalendar
    },
  },
  methods: {
    calcStats(r, key, cal) {
      return {
        ...r,
        key,
        pars: this.courses[r.course][r.tees].pars,
        hnds: this.courses[r.course][r.tees].hnds,
        totals: this.totals(r.scores),
        thru: _.countBy(r.scores, s => s > 0),
        gross: this.calcGross(r),
        net: this.calcNet(r),
        adj: this.calcAdjusted(r),
        pops: this.calcPops(r),
        birds: this.calcBirds(r, cal),
        deuces: this.calcDeuces(r),
        chicago: this.calcChicago(r),
        modChicago: this.calcModChicago(r),
        sidePoints: this.calcSidePoints(r, key),
        sideMoney: this.calcSideMoney(r, key),
      }
    },
    totals(scores) {
      return [
        _.sum(scores.slice(0, 9)),
        _.sum(scores.slice(9,18)),
        _.sum(scores)
      ]
    },
    calcGross(round) {
      return this.totals(round.scores.map((score, i) => {
        return (score > 0) ? score - this.courses[round.course][round.tees].pars[i] : 0
      }))
    },
    calcNet(round) {
      if (round.type == 'vegas' || round.type == 'mbWed') {
        return this.totals(round.scores.map((score, i) => {
          return (score > 0) ? score - this.courses[round.course][round.tees].pars[i] - (round.index / 18) : 0
        }))
      } else {
        let pops  = this.calcPops(round)
        return this.totals(round.scores.map((score, i) => {
          return (score > 0) ? score - this.courses[round.course][round.tees].pars[i] - pops[i] : 0
        }))
      }
    },
    calcBirds(round, cal) {
      let dd = (cal && cal.type == 'vegas' && cal.ddHole) ? cal.ddHole : []
      let birdMult = (cal && cal.type == 'mbWed' && cal.birdMult) ? cal.birdMult :1
      let pars = this.courses[round.course][round.tees].pars
      let birds =  round.scores.map((score, index) => {
        let ddm = (dd.includes(index + 1)) ? 2 : 1
        let bird = 0
        if (score > 0) {
          bird = ((score > pars[index]) ? ((pars[index] - (score - this.calcPops(round)[index])) * 0.5) * ddm : ((pars[index] - score) + (this.calcPops(round)[index] * 0.5)) * ddm) * birdMult
        }
        return (bird > 0) ? bird : 0
      })
      return birds
    },
    calcDeuces(round) {
      return round.scores.map((score, index) => {
        let p =  (this.calcPops(round)[index] > 0) ? 1 : 0
        return (score && score - p <= 2) ? 1 : 0
      })
    },
    calcPops(round) {
      return this.courses[round.course][round.tees].hnds.map((hnd) => {
          let pop = (Math.floor(Math.round(round.index) / 18) + ((Math.round(round.index) % 18 >= hnd) ? 1 : 0))
          return (pop > 0) ? pop : 0
      })
    },
    calcChicago(round) {
      let pars = this.courses[round.course][round.tees].pars
      return round.scores.map((score, i) => {
        return (score > 0 && (pars[i] - score) > -2) ? 2 * Math.pow(2, (pars[i] - score)) : 0
      })
    },
    calcModChicago(round) {
      let pars = this.courses[round.course][round.tees].pars
      return round.scores.map((score, i) => {
        return (score > 0) ? (pars[i] - score) > -2 ? 2 * Math.pow(2, (pars[i] - score)) : -1 : 0
      })
    },
    calcAdjusted(round) {
      let pars = this.courses[round.course][round.tees].pars
      let pops = this.calcPops(round)
      let adj = round.scores.map((s, i) => {
        return (s > pars[i] + pops[i] + 2) ? pars[i] + pops[i] + 2 : s
      })
      return [_.sum(adj), adj]
    },
    calcSidePoints(round, key) {
      let points = 0
      for (let hole in round.sideGameResults) {
        if (round.sideGameResults[hole].team.includes(key)) points += round.sideGameResults[hole].points
      }
      return points
    },
    calcSideMoney(round, key) {
      let money = 0
      for (let hole in round.sideGameResults) {
        let h = round.sideGameResults[hole]
        if (h.team.includes(key)) {
          money += h.money
        } else  {
          money -= (h.money * h.team.length) / (round.roundKeys.length - h.team.length)
        }
      }
      return money
    },
    async getLeagueIndex(key, type) {
        let cal = await this.getCal(type)
        let playerDoc = await getDoc(doc(playerRef,key))
        let player = playerDoc.data()
        let allrounds = []
        let prev = await getDocs(query(prevRoundRef, where('playerKey', '==', key), where('type', '==', type), orderBy('iso', 'desc')))        
        let rounds = await getDocs(query(roundRef, where('playerKey', '==', key), where('type', '==', type), orderBy('iso', 'desc')))
        allrounds = rounds.docs.concat(prev.docs)
        let fullRounds = []
        allrounds.forEach(r => {
          let round = r.data()
          if (fullRounds.length < 10) {
            if (!round.scores.includes(0) && (cal[round.iso].status == 'mdi-check-bold' || cal[round.iso].status == 'mdi-alpha-h-circle-outline')) {
              let pars = this.courses[round.course][round.tees].pars
              let pops = this.calcPops(round)
              let adj = round.scores.map((s, i) => {
                return (s > pars[i] + pops[i] + 2) ? pars[i] + pops[i] + 2 : s
              })
              fullRounds.push((_.sum(adj) - this.courses[round.course][round.tees].rating) * (113 / this.courses[round.course][round.tees].slope))
            }
          }
        })
        if (fullRounds.length < 6) {
          for (let i = fullRounds.length; i < 6; i++) {
            fullRounds.push(player.ghin)
          }
        }
        let num = (fullRounds.length > 4) ? 4 : fullRounds.length
        let handy = _.sum(fullRounds.sort((a,b) => { return a-b }).slice(0,num)) / num
        console.log(player.name,  fullRounds.length, handy, fullRounds.sort((a,b) => { return a-b }).slice(0,num), num);
        return((handy > 36) ? 35.999 : handy)
    },
    async getLeagueIndexData(key, type) {
      // console.log(key, type);
      let cal = await this.getCal(type)
      let playerDoc = await getDoc(doc(playerRef,key))
      let player = playerDoc.data()
      let allrounds = []
      let prev = await getDocs(query(prevRoundRef, where('playerKey', '==', key), where('type', '==', type), orderBy('iso', 'desc')))        
      let rounds = await getDocs(query(roundRef, where('playerKey', '==', key), where('type', '==', type), orderBy('iso', 'desc')))
      allrounds = rounds.docs.concat(prev.docs)
      let fullRounds = []
      let i = 1
      allrounds.forEach((r) => {
        let round = r.data()
        if (fullRounds.length < 10) {
          if (!round.scores.includes(0) && (cal[round.iso].status == 'mdi-check-bold' || cal[round.iso].status == 'mdi-alpha-h-circle-outline')) {
            let pars = this.courses[round.course][round.tees].pars
            let pops = this.calcPops(round)
            let adj = round.scores.map((s, i) => {
              return (s > pars[i] + pops[i] + 2) ? pars[i] + pops[i] + 2 : s
            })
            fullRounds.push({
              round_week: i,
              round_index: (_.sum(adj) - this.courses[round.course][round.tees].rating) * (113 / this.courses[round.course][round.tees].slope),
              round_adj: _.sum(adj),
              round_id: r.id,
              round_total: _.sum(round.scores),
              round_date: round.date,
              type: 'round'
            })
            i++
          }
        }
      })
      // console.log(fullRounds);
      if (fullRounds.length < 6) {
        for (let i = fullRounds.length; i < 6; i++) {
          fullRounds.push({round_index: player.ghin, type: 'GHIN'})
        }
      }
      let num = (fullRounds.length > 4) ? 4 : fullRounds.length
      let frSorted = fullRounds.sort((a,b) => { return a.round_index-b.round_index }).slice(0,num)
      let handy = _.sumBy(frSorted, 'round_index') / num
      // console.log(player.name,  fullRounds.length, handy, fullRounds.sort((a,b) => { return a-b }).slice(0,num), num);
      return({rounds: fullRounds, handy: (handy > 36) ? 35.999 : handy})
  },
    async getCal(type) {
      let py = moment().subtract(1, 'years').format('YYYY')
        let cy = moment().format('YYYY')
        let cal = {}
        let docs = await getDocs(query(collection(fs, 'years', cy ,'calendar')), where('type', '==', type))
        docs.docs.forEach(d => {
          cal[d.data().iso] = d.data()
        })
        docs = await getDocs(query(collection(fs, 'years', py, 'calendar')), where('type', '==', type))
        docs.docs.forEach(d => {
          cal[d.data().iso] = d.data()
        })
        return cal
    },
    async calcPayout(type) {
      let pay = {
        vegas: [45,35,30,25,20,15,0,0,0,0,0,0],
        mbWed: [35,30,25,20,15,10,0,0,0,0,0,0,0,0,0,0]
      }
      let cals = await getDocs(query(calRef, where('type', '==', type), orderBy('iso')))
      
      cals.forEach(async cal => {
        let docs = await getDocs(query(roundRef, where('iso', '==', cal.iso), where('type', '==', cal.type)))
        docs.forEach(doc => {
          let d = doc.data()
          if (!d.scores.includes(0)) {
            stats.push(this.calcStats(d, doc.id))
          }
        })
        console.log(d);
      })

      // let totals = stats.map(r => {
      //   if (!des) {
      //     return {name: r.name, key: r.playerKey, total: _.sum(r.scores) - r.index}
      //   } else {
      //     return {name: r.name, key: r.playerKey, total: _.sum(r.chicago) + (r.index - 36)}
      //   }
      // })
      // let sorted = totals.sort((a, b) => {
      //   if (des) {
      //     return b.total - a.total
      //   } else {
      //     return a.total - b.total
      //   }
      // })
      // let top = {1:[],2:[],3:[],4:[],5:[], 6:[]}
      // let n = 1
      // sorted.forEach((s,i) => {
      //   if (n < 7) top[n].push(s)
      //   if (sorted[i+1] && sorted[i].total != sorted[i+1].total) n++
      // })
      // n = 0
      // console.log(top);
      // for (let t in top) {
      //   let m = pay[type][n]
      //   for (let k = 1; k < top[t].length; k++) {
      //     m += pay[type][n + k]
      //     n++
      //   }
      //   let mon = m * mult / top[t].length
      //   n += t.length
      //   top[t].forEach(v => {
      //     if (mon > 0) {
      //       v.money = mon
      //       v.date = date
      //     }
      //   })
      // }
      // console.log(date, mult, top);
      // setDoc(doc(fs, 'calendar', type), {[date]: {'payout': top}}, {merge:true})
    },
    async updateLeague(week) {
      let cRef = collection(fs, 'years', week.year, 'rounds')
      let stats = []
      let rounds = await getDocs(query(cRef, where('type', '==', week.type), where('iso', '==', week.iso)))
      rounds.forEach(round => {
        let roundStats = this.calcStats(round.data(), round.id, week)
        stats.push({name: roundStats.name, key: roundStats.key, birds: _.sum(roundStats.birds), deuces: _.sum(roundStats.deuces)})
      })
      return(stats)
    }
  }
}