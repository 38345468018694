<template>
  <div id="scores" class="pa-0">

    <div v-if="round.net">
      <v-row no-gutters class="d-flex justify-space-between px-2 title">
        <span>{{round.name}}</span>
        <span v-if="round.type == 'vegas' || round.type == 'mbWed'">{{round.net[2].toFixed(3)}}</span>
        <span v-else>{{round.net[2]}}</span>
      </v-row>

      <v-divider></v-divider>

      <v-row no-gutters class="d-flex justify-space-between text-center">
        <v-col v-if="round.type == 'vegas' || round.type == 'mbWed'">Index: {{round.index.toFixed(3)}}</v-col>
        <v-col v-else>Index: {{round.index}}</v-col>
        <v-col v-if="side < 2" cols="3">{{(side) ? 'Back' : 'Front'}}: {{(side) ? round.totals[1] : round.totals[0]}}</v-col>
        <v-col>Total: {{round.totals[2]}}</v-col>
      </v-row>

      <v-divider></v-divider>

      <div v-if="round.sideGame && round.sideGame.game && side < 3">
        <v-row no-gutters class="d-flex justify-space-between text-center body-2">
          <v-col>Points: {{round.sidePoints}}</v-col>
          <v-col>Money: ${{round.sideMoney.toFixed(2)}}</v-col>
        </v-row>

        <v-divider></v-divider>
      </div>

      <div class="d-flex justify-end px-2 title" v-if="side == 3">Front: {{round.totals[0]}}</div>
      <v-divider v-if="side == 3"></v-divider>
      <v-row v-if="side == 0 || side == 3" no-gutters class="d-flex justify-space-around">
        <v-col v-for="(n, s) in 9" :key="n">
          <v-sheet class="hole-label line-h1 bg3 mt-1 text-center" :class="{'primary--text': isDD.includes(n)}">{{n}}</v-sheet>
          <v-sheet class="score bg2 mb-2 px-1 title text-no-wrap" :class="{'red--text': round.scores[s] < round.pars[s], 'blue--text': round.scores[s] > round.pars[s]}" min-height="36px" @click="getScores(s)">
            <span class="text-no-wrap" :class="scoreType(round.scores[s],round.pars[s])">{{(round.scores[s]) ? round.scores[s] : null }}</span>
            <span v-if="(round.type == 'vegas' || round.type == 'mbWed') && round.birds[s]" class="badge ur"><span v-if="Math.floor(round.birds[s])">{{Math.floor(round.birds[s])}}</span><span v-if="round.birds[s] % 1 > 0">&#189;</span></span>
            <span v-if="round.type == 'vegas' && round.deuces[s]" class="badge ll">2</span>
            <span v-if="isChicago && round.chicago[s]" class="badge lr">{{round.chicago[s]}}</span>
            <span v-if="isChicago == 'mod' && round.modChicago[s]" class="badge lr">{{round.modChicago[s]}}</span>
            <span v-if="side < 3 && round.sideGameResults[s] && round.sideGameResults[s].team.includes(round.key)" class="badge ul">{{round.sideGameResults[s].points}}</span>
            <span class="pops primary--text"><span v-for="p in round.pops[s]" :key="round.key + '-' + p">&#8226;</span></span>
          </v-sheet>
        </v-col>
      </v-row> 

      <div class="d-flex justify-end px-2 title" v-if="side == 3">Back: {{round.totals[1]}}</div>
      <v-divider v-if="side == 3"></v-divider>
      <v-row v-if="side == 1 || side == 3" no-gutters class="d-flex justify-space-around">
        <v-col v-for="(n, s) in 9" :key="n">
          <v-sheet class="hole-label line-h1 bg3 mt-1 text-center" :class="{'primary--text': isDD.includes(n + 9)}">{{n + 9}}</v-sheet>
          <v-sheet class="score bg2 mb-2 px-1 title" :class="{'red--text': round.scores[s + 9] < round.pars[s + 9], 'blue--text': round.scores[s + 9] > round.pars[s + 9]}" min-height="36px" @click="getScores(s + 9)">
            <span class="text-no-wrap" :class="scoreType(round.scores[s + 9],round.pars[s + 9])">{{(round.scores[s + 9]) ? round.scores[s + 9] : null }}</span>
            <span v-if="(round.type == 'vegas' || round.type == 'mbWed') && round.birds[s + 9]" class="badge ur"><span v-if="Math.floor(round.birds[s + 9])">{{Math.floor(round.birds[s + 9])}}</span><span v-if="round.birds[s + 9] % 1 > 0">&#189;</span></span>
            <span v-if="round.type == 'vegas' && round.deuces[s + 9]" class="badge ll">2</span>
            <span v-if="isChicago && round.chicago[s + 9]" class="badge lr">{{round.chicago[s + 9]}}</span>
            <span v-if="isChicago == 'mod' && round.chicago[s + 9]" class="badge lr">{{round.modChicago[s + 9]}}</span>
            <span v-if="side < 3 && round.sideGameResults[s + 9] && round.sideGameResults[s + 9].team.includes(round.key)" class="badge ul">{{round.sideGameResults[s + 9].points}}</span>
            <span class="pops primary--text"><span v-for="p in round.pops[s + 9]" :key="round.key + '-' + p">&#8226;</span></span>
          </v-sheet>
        </v-col>
      </v-row> 

      <div v-if="side == 3" class="px-4 title">
        <div>Net Score: {{round.net[2].toFixed(3)}}</div>
        <div>Adjusted Score: {{round.adj[0]}}</div>
      </div> 
    </div>
  </div>
    
</template>

<script>

import { mixin } from '@/js/mixin'
// import { fs } from '@/db'


export default {
  name: 'Scores',
  props: ['side', 'round', 'edit', 'cal'],
  mixins: [mixin],
  data: () => {
    return {
      isChicago: false,
      isDD: []
    }
  },
  computed: {
    calendar() {
      return this.$store.getters.getCalendar
    },
  },
  methods: {
    getScores(i) {
      if (this.edit == 'true') {
        this.$emit('clicked', i)
      }
    },
    isChic() {
      // console.log(this.side, this.round, this.edit, this.cal);
      this.isChicago = (this.cal.game && (this.cal.game.includes("Chicago Points") || this.cal.game.includes("Modified Chicago"))) ? true : false
    },
    getDD() {
      this.isDD = (this.cal.type == 'vegas' && this.cal.ddHole) ? this.cal.ddHole : []
    },
    scoreType(score, par) {
      if (score > 0 && score - par < -1) {
        return {'eagle-circle': true}
      } else if (score > 0 && score - par == -1) {
        return {'bird-circle': true}
      } else if (score > 0 && score - par == 1) {
        return {'bogey-square': true}
      } else if (score > 0 && score - par > 1) {
        return {'double-square ': true}
      } else {
        return false
      }
    }
  },
  mounted() {
    this.isChic() 
    this.getDD()
  }
}
</script>

<style>
.bird-circle { border: 1px solid #777; border-radius: 50%; padding: 0 .2rem 0 .2rem;}
.eagle-circle { border: double #777; border-radius: 50%; padding: 0 .2rem 0 .2rem;}
.bogey-square { border: 1px solid #777; padding: 0 .2rem 0 .2rem;}
.double-square { border: double #777; padding: 0 .2rem 0 .2rem;}
</style>